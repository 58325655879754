/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    br: "br",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    ul: "ul",
    li: "li",
    ol: "ol",
    em: "em"
  }, _provideComponents(), props.components), {Specialization, Skill, Condition, Boon, Instability, Trait, Item, Divider, Grid, GridItem, Traits, Card, Effect, Control} = _components;
  if (!Boon) _missingMdxReference("Boon", true);
  if (!Card) _missingMdxReference("Card", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Control) _missingMdxReference("Control", true);
  if (!Divider) _missingMdxReference("Divider", true);
  if (!Effect) _missingMdxReference("Effect", true);
  if (!Grid) _missingMdxReference("Grid", true);
  if (!GridItem) _missingMdxReference("GridItem", true);
  if (!Instability) _missingMdxReference("Instability", true);
  if (!Item) _missingMdxReference("Item", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Trait) _missingMdxReference("Trait", true);
  if (!Traits) _missingMdxReference("Traits", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The ", React.createElement(Specialization, {
    name: "Tempest",
    text: "Power Tempest"
  }), " is a strong DPS variant for fractals with various utilities the ", React.createElement(Specialization, {
    name: "Weaver"
  }), " counterpart doesn't offer."), "\n", React.createElement(_components.p, null, "It has very high burst damage in fast-paced fights and its overall DPS is only contested by the ", React.createElement(_components.a, {
    href: "/builds/elementalist/power-weaver"
  }, "Sword Weaver"), " on huge hitboxes.", React.createElement(_components.br), "\n", "Thanks to ", React.createElement(Skill, {
    name: "Overload Air",
    id: "29719"
  }), ", the group's damage is buffed by around 1k DPS, so at least one ", React.createElement(Specialization, {
    name: "Tempest"
  }), " is never a bad idea when thinking about group composition."), "\n", React.createElement(_components.p, null, "Apart from great DPS, the build afflicts tons of ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " and helps keeping up ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "Heat Sync",
    id: "29548"
  }), "."), "\n", React.createElement(_components.p, null, "For projectile-heavy scenarios, ", React.createElement(Skill, {
    name: "Aftershock",
    id: "30432"
  }), " and ", React.createElement(Skill, {
    name: "Sand Squall",
    id: "29453"
  }), " can be used, ", React.createElement(Skill, {
    name: "Shocking Aura",
    id: "5527"
  }), " and ", React.createElement(Skill, {
    name: "Cyclone",
    id: "30008"
  }), " help against trash mobs and defiance bars."), "\n", React.createElement(_components.p, null, "Contrary to the ", React.createElement(_components.a, {
    href: "/builds/elementalist/power-weaver"
  }, "Sword Weaver"), ", melee hate like ", React.createElement(Instability, {
    name: "Social Awkwardness"
  }), " or disruptions like ", React.createElement(Instability, {
    name: "Last Laugh"
  }), " don't matter that much since you can stand on range and have access to a multitude of defensive mechanics like ", React.createElement(Boon, {
    name: "Stability"
  }), " and ", React.createElement(Trait, {
    name: "Gale Song",
    id: "1952"
  }), "."), "\n", React.createElement(_components.p, null, "The degree of difficulty is a little bit higher than the other ", React.createElement(Specialization, {
    name: "Elementalist"
  }), " builds, as you need to adhere to many small things to achieve top numbers.", React.createElement(_components.br), "\n", "The positioning and timing of skills matter a lot, a single mistake can make your DPS end up several thousand below other damage dealers."), "\n", React.createElement(_components.p, null, "The build benefits heavily from slaying potions and sigils such as ", React.createElement(Item, {
    id: "50082",
    id: "50082"
  }), " and ", React.createElement(Item, {
    name: "Impact",
    type: "Sigil",
    id: "24868"
  }), "."), "\n", React.createElement(Divider, {
    text: "Equipment"
  }), "\n", React.createElement(Divider, {
    text: "Build"
  }), "\n", React.createElement(Grid, null, React.createElement(GridItem, {
    sm: "7"
  }, React.createElement(Traits, {
    traits1: "Water",
    traits1SelectedIds: "363,349,361",
    traits2: "Air",
    traits2Selected: "Ferocious Winds, Stormsoul, Fresh Air",
    traits3: "Tempest",
    traits3SelectedIds: "1886,1902,1839",
    traits1SelectedIds: "363,349,361",
    traits3SelectedIds: "1886,1902,1839",
    traits1Id: "17",
    traits2Id: "41",
    traits2SelectedIds: "232,1502,1503",
    traits3Id: "48"
  }), React.createElement(Card, {
    title: "Situational Skills"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, "Heal"), React.createElement(_components.td)), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Glyph of Elemental Harmony",
    size: "big",
    disableText: true,
    id: "5569"
  })), React.createElement(_components.td, null, "A stronger healing alternative if ", React.createElement(Boon, {
    name: "Might"
  }), " uptime is good.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Wash the pain away",
    size: "big",
    disableText: true,
    id: "29535"
  })), React.createElement(_components.td, null, "Another healing alternative affecting allies close-by.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Offensive"), React.createElement(_components.td)), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Arcane Wave",
    size: "big",
    disableText: true,
    id: "5638"
  })), React.createElement(_components.td, null, "Use this skill instead of ", React.createElement(Skill, {
    name: "Arcane Blast",
    id: "5539"
  }), " if you need extra cleave damage at the cost of some target dps. Also useful to generate ", React.createElement(Boon, {
    name: "Might"
  }), " with a fire field.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Conjure Frostbow",
    size: "big",
    disableText: true,
    id: "5567"
  })), React.createElement(_components.td, null, "Faster burst than ", React.createElement(Skill, {
    name: "Conjure Lightning Hammer",
    id: "5624"
  }), " on large hitboxes with the bonus of being semi-ranged.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Defensive"), React.createElement(_components.td)), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Arcane Shield",
    size: "big",
    disableText: true,
    id: "5641"
  })), React.createElement(_components.td, null, "Provides three blocks for things like the console in ", React.createElement(_components.a, {
    href: "/fractals/underground-facility"
  }, "Underground Facility Fractal"), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Armor of Earth",
    size: "big",
    disableText: true,
    id: "5639"
  })), React.createElement(_components.td, null, "Another defensive utility skill granting ", React.createElement(Boon, {
    name: "Protection"
  }), " and ", React.createElement(Boon, {
    name: "Stability"
  }), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Aftershock",
    size: "big",
    disableText: true,
    id: "30432"
  })), React.createElement(_components.td, null, "A group-wide reflect aura lasting for 4 seconds.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Utility"), React.createElement(_components.td)), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Lightning Flash",
    size: "big",
    disableText: true,
    id: "5536"
  })), React.createElement(_components.td, null, "A teleport with a range of 900 units. Very important skill for certain skips, especially in combination with ", React.createElement(Item, {
    name: "White Mantle Portal Device",
    id: "78978"
  }), ", useful in fractals like ", React.createElement(_components.a, {
    href: "/fractals/cliffside"
  }, "Cliffside Facility Fractal"), ", ", React.createElement(_components.a, {
    href: "/fractals/aetherblade"
  }, "Aetherblade Fractal"), " and ", React.createElement(_components.a, {
    href: "/fractals/underground-facility"
  }, "Underground Facility Fractal"), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Eye of the Storm",
    size: "big",
    disableText: true,
    id: "30047"
  })), React.createElement(_components.td, null, "Grants ", React.createElement(Effect, {
    name: "Superspeed"
  }), ", useful for skips, especially in combination with ", React.createElement(Item, {
    name: "Executioner Axe Toy",
    id: "49940"
  }), " or ", React.createElement(Item, {
    name: "endlesschoyapiatatonic",
    id: "85244"
  }), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Signet of Fire",
    size: "big",
    disableText: true,
    id: "5542"
  })), React.createElement(_components.td, null, "If you don't reach 100% critical chance and are too lazy to get Assassin's pieces, take this instead of ", React.createElement(Skill, {
    name: "Arcane Blast",
    id: "5539"
  }), ".")))))), React.createElement(GridItem, {
    sm: "5"
  }, React.createElement(Card, {
    title: "Swap Weapons"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Scepter with ", React.createElement(Item, {
    name: "Night",
    type: "Sigil",
    id: "36053"
  })), "\n", React.createElement(_components.li, null, "Warhorn with ", React.createElement(Item, {
    name: "Serpent Slaying",
    type: "Sigil",
    id: "24658"
  })), "\n", React.createElement(_components.li, null, "Scepters with slaying sigils (see ", React.createElement(_components.a, {
    href: "/guides/consumables"
  }, "Consumables Guide"), ")"), "\n", React.createElement(_components.li, null, "A dagger for ", React.createElement(Skill, {
    name: "Ring of Fire",
    id: "5691"
  }), " (fire field for ", React.createElement(Boon, {
    name: "Might"
  }), "-stacking) and ", React.createElement(Skill, {
    name: "Ride the Lightning",
    id: "5529"
  }), " (skipping)"), "\n")), React.createElement(Card, {
    title: "Situational Traits"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Gale Song",
    size: "big",
    disableText: true,
    id: "1952"
  })), React.createElement(_components.td, null, "Useful automatic group stunbreak for things like ", React.createElement(Instability, {
    name: "Last Laugh"
  }), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Soothing Power",
    size: "big",
    disableText: true,
    id: "2028"
  })), React.createElement(_components.td, null, "Use this instead of ", React.createElement(Trait, {
    name: "Powerful Aura",
    id: "361"
  }), " when you are not using ", React.createElement(Trait, {
    name: "Unstable Conduit",
    id: "1886"
  }), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Raging Storm",
    size: "big",
    disableText: true,
    id: "214"
  })), React.createElement(_components.td, null, "If you can't profit from ", React.createElement(Trait, {
    name: "Stormsoul",
    id: "1502"
  }), " (e.g. Warden Amala in ", React.createElement(_components.a, {
    href: "/fractals/twilight-oasis"
  }, "Twilight Oasis"), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Trait, {
    name: "One with Air",
    size: "big",
    disableText: true,
    id: "224"
  })), React.createElement(_components.td, null, "Useful for skipping with ", React.createElement(Effect, {
    name: "Superspeed"
  }), ", especially in combination with ", React.createElement(Item, {
    name: "Executioner Axe Toy",
    id: "49940"
  }), " or ", React.createElement(Item, {
    name: "endlesschoyapiatatonic",
    id: "85244"
  }), "."))))), React.createElement(Card, {
    title: "Defiance Bar Damage"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Shocking Aura",
    size: "big",
    disableText: true,
    id: "5527"
  })), React.createElement(_components.td, null, "100 with ", React.createElement(Control, {
    name: "Stun"
  }))), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Cyclone",
    size: "big",
    disableText: true,
    id: "30008"
  })), React.createElement(_components.td, null, "150 with ", React.createElement(Control, {
    name: "Pull"
  }))), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Blinding Flash",
    size: "big",
    disableText: true,
    id: "5694"
  })), React.createElement(_components.td, null, "20 / s with ", React.createElement(Condition, {
    name: "Blinded"
  }))), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Wind Blast",
    size: "big",
    disableText: true,
    id: "5733"
  })), React.createElement(_components.td, null, "232 with ", React.createElement(Control, {
    name: "Launch"
  }))), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Dust Storm",
    size: "big",
    disableText: true,
    id: "30336"
  })), React.createElement(_components.td, null, "20 / s with ", React.createElement(Condition, {
    name: "Blinded"
  }))), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Tidal Surge",
    size: "big",
    disableText: true,
    id: "30864"
  })), React.createElement(_components.td, null, "150 with ", React.createElement(Control, {
    name: "Knockback"
  })))))))), "\n", React.createElement(Divider, {
    text: "Details"
  }), "\n", React.createElement(Grid, null, React.createElement(GridItem, {
    sm: "6"
  }, React.createElement(Card, {
    title: "Skill Priority"
  }, React.createElement(_components.p, null, "Your entire rotation revolves around using ", React.createElement(Skill, {
    name: "Overload Air",
    id: "29719"
  }), " as often as possible."), React.createElement(_components.p, null, "Apart from that, you have the following modifiers:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Trait, {
    id: "1839",
    id: "1839"
  }), ": 7% damage and condition damage for 7 seconds after ", React.createElement(Skill, {
    name: "Overload Air",
    id: "29719"
  })), "\n", React.createElement(_components.li, null, React.createElement(Trait, {
    name: "Fresh Air",
    id: "1503"
  }), ": 250 ferocity for 5 seconds after attuning to air"), "\n", React.createElement(_components.li, null, React.createElement(Trait, {
    name: "Stormsoul",
    id: "1502"
  }), " and ", React.createElement(Item, {
    name: "Impact",
    type: "Sigil",
    id: "24868"
  }), " when the target's defiance bar is broken"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Conjure Lightning Hammer",
    id: "5624"
  }), ": 75 ferocity while wielding it"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Conjure Fiery Greatsword",
    id: "5516"
  }), ": 260 power while wielding it"), "\n"), React.createElement(_components.p, null, "You want to use your most damaging skills while these modifiers are active.", React.createElement(_components.br), "\n", "Therefore your basic rotation optimally is:"), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Overload Air",
    id: "29719"
  }), "\n"), "\n", React.createElement(_components.li, null, "Attune to ", React.createElement(_components.em, null, "something"), " else"), "\n", React.createElement(_components.li, null, "Critically hit with ", React.createElement(_components.em, null, "something"), " fast to trigger ", React.createElement(Trait, {
    name: "Fresh Air",
    id: "1503"
  })), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Air Attunement",
    id: "5494"
  }), "\n"), "\n", React.createElement(_components.li, null, "High damage skills like ", React.createElement(Skill, {
    name: "Lightning Storm",
    id: "5737"
  }), ", ", React.createElement(Skill, {
    name: "Lightning Orb",
    id: "30795"
  }), " and ", React.createElement(Skill, {
    name: "Arcane Blast",
    id: "5539"
  })), "\n", React.createElement(_components.li, null, "Repeat"), "\n"), React.createElement(_components.p, null, "In reality, the ", React.createElement(_components.em, null, "something"), " looks like the following:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "In ", React.createElement(Skill, {
    name: "Fire Attunement",
    id: "5492"
  }), ":", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Wildfire",
    id: "29533"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Phoenix",
    id: "5675"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Dragons Tooth",
    id: "5692"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "In ", React.createElement(Skill, {
    name: "Earth Attunement",
    id: "5495"
  }), ":", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Dust Storm",
    id: "30336"
  }), " (only on large hitboxes)"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "In ", React.createElement(Skill, {
    name: "Water Attunement",
    id: "5493"
  }), ":", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Shatterstone",
    id: "5538"
  }), " (useful for ", React.createElement(Condition, {
    name: "Vulnerability"
  }), ")"), "\n"), "\n"), "\n"), React.createElement(_components.p, null, "When attuning back to ", React.createElement(Skill, {
    name: "Air Attunement",
    id: "5494"
  }), ", you will have 5 seconds cooldown before you can use ", React.createElement(Skill, {
    name: "Overload Air",
    id: "29719"
  }), " again.", React.createElement(_components.br), "\n", "During this window you want to use your conjured weapons:"), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Conjure Lightning Hammer",
    id: "5624"
  }), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Invoke Lightning",
    id: "5725"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Lightning Swing",
    id: "5726"
  }), " => ", React.createElement(Skill, {
    name: "Static Swing",
    id: "5727"
  }), " => ", React.createElement(Skill, {
    name: "Thunderclap",
    profession: "bundle",
    id: "5728"
  })), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Conjure Fiery Greatsword",
    id: "5516"
  }), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Fiery Rush",
    id: "5517"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Firestorm",
    profession: "bundle",
    id: "5531"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Fiery Whirl",
    id: "5697"
  }), " (if you can whirl against a wall or have to switch targets)"), "\n"), "\n"), "\n"), React.createElement(_components.p, null, "And lastly, you should use ", React.createElement(Skill, {
    name: "Lightning Strike",
    id: "5561"
  }), " (instant cast) off recharge and ", React.createElement(Skill, {
    name: "Heat Sync",
    id: "29548"
  }), " whenever ", React.createElement(Boon, {
    name: "Might"
  }), " is low on the group."))), React.createElement(GridItem, {
    sm: "6"
  }, React.createElement(Card, {
    title: "Opener"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "If a Mistlock Singularity is present", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Conjure Fiery Greatsword",
    id: "5516"
  }), " and reset its cooldown with it"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Air Attunement",
    id: "5494"
  }), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "Overload Air",
    id: "29719"
  }), " so it finishes when the fight starts"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Firestorm",
    profession: "bundle",
    id: "5531"
  }), " (if you are wielding a ", React.createElement(Skill, {
    name: "Conjure Fiery Greatsword",
    disableText: true,
    id: "5516"
  }), ")"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Lightning Orb",
    id: "30795"
  }), " (from range, ideally it should pass through the mob/boss when its defiance bar is broken)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Lightning Storm",
    id: "5737"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Fire Attunement",
    id: "5492"
  }), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Wildfire",
    id: "29533"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Dragons Tooth",
    id: "5692"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Phoenix",
    id: "5675"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Earth Attunement",
    id: "5495"
  }), " (only on huge hitboxes)", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Dust Storm",
    id: "30336"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Air Attunement",
    id: "5494"
  }), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Conjure Lightning Hammer",
    id: "5624"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Invoke Lightning",
    id: "5725"
  }), " (inside the target's hitbox)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Lightning Swing",
    id: "5726"
  }), " => ", React.createElement(Skill, {
    name: "Static Swing",
    id: "5727"
  }), " => ", React.createElement(Skill, {
    name: "Thunderclap",
    profession: "bundle",
    id: "5728"
  }), " and 3x ", React.createElement(Skill, {
    name: "Arcane Blast",
    id: "5539"
  })), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Overload Air",
    id: "29719"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Fire Attunement",
    id: "5492"
  }), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Dragons Tooth",
    id: "5692"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Air Attunement",
    id: "5494"
  }), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Conjure Fiery Greatsword",
    id: "5516"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Fiery Rush",
    id: "5517"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Firestorm",
    profession: "bundle",
    id: "5531"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Lightning Orb",
    id: "30795"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Overload Air",
    id: "29719"
  }), "\n"), "\n"), "\n"), "\n")), React.createElement(Card, {
    title: "Advanced Notes"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Always cast ", React.createElement(Skill, {
    name: "Lightning Orb",
    id: "30795"
  }), " from as far as possible."), "\n", React.createElement(_components.li, null, "Try to place the outer ring of ", React.createElement(Skill, {
    name: "Overload Air",
    id: "29719"
  }), " directly on the target and make sure the cast finishes."), "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "Invoke Lightning",
    id: "5725"
  }), " while standing inside the target's hitbox to make sure all strikes hit."), "\n", React.createElement(_components.li, null, "Remember to already switch to the next attunement ", React.createElement(_components.em, null, "while"), " casting skills."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Arc Lightning",
    id: "5526"
  }), " (your ", React.createElement(Skill, {
    name: "Air Attunement",
    id: "5494"
  }), " auto-attack) deals more damage the longer you channel it, but every other skill has a higher priority."), "\n")))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
