import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}));

export default function ArchiveWarning() {
  const { classes } = useStyles();

  return (
    <Alert severity="error" className={classes.root}>
      <AlertTitle>ARCHIVED BUILD</AlertTitle>
      The archive contains builds that are not actively maintained any more! The
      information you find here might be outdated, irrelevant or even
      misleading.
    </Alert>
  );
}
